import React from 'react';
import { Text, useDevice } from '@treatwell/ui';

import { Address } from 'js/model/rainbow/Address';
import { CmsVenuePage } from 'js/model/cms/cms-venue-page';

import { VenueChain, VenueOutput } from 'js/model/rainbow/venue/VenueOutput';
import styles from './style.module.css';
import { trackMParticleVenuePageEvent } from '../../tracking';

interface Props {
  address: Address;
  chain?: VenueChain;
  cmsChainTemplate: CmsVenuePage['page']['venue']['details']['chain'];
  venue: VenueOutput;
  countryCode: string;
}

// Create an unwrappable address part.
function unwrappableAddressPart(
  part: string,
  index: number,
  parts: string[]
): React.ReactElement {
  // All but the last part should be suffixed with a comma
  const suffixComma = index < parts.length - 1 ? ',' : '';

  return (
    <Text key={part}>
      <Text className={styles.addressPart} type="smHeader">
        {part}
        {suffixComma}
      </Text>
      {/*
            The trailing space must the outside of the unwrappable
            element. If it were to be inside, all of the unwrappable
            elements would form one large chunk of unwrappable text.
        */}
      <span> </span>
    </Text>
  );
}

function renderChain(
  cmsChainTemplate: string,
  chain?: VenueChain
): React.ReactElement | null {
  if (!chain) {
    return null;
  }

  const text = cmsChainTemplate.replace('{0}', chain.name).replace('.', '');
  return (
    <a href={chain.uri.desktopUri} className={styles.chainLink}>
      {text}
    </a>
  );
}

export function FormattedAddress({
  address,
  chain,
  cmsChainTemplate,
  venue,
  countryCode,
}: Props): React.ReactElement {
  // Collect all address parts.
  const parts = [...address.addressLines];
  if (address.postalCode) {
    parts.push(address.postalCode);
  }

  if (chain) {
    parts[parts.length - 1] = `${parts[parts.length - 1]} -`;
  }

  const { isMobile } = useDevice();

  // Convert each address part to an element that
  // may not be wrapped.
  // If wrapping occurs it should be between parts,
  // not within them.
  const elements = parts.map(unwrappableAddressPart);

  return (
    <Text
      data-cy="VenueAddress"
      type="smHeader"
      onClick={() =>
        trackMParticleVenuePageEvent(
          'venue_address_clicked',
          countryCode,
          isMobile,
          venue
        )
      }
    >
      {elements}
      {renderChain(cmsChainTemplate, chain)}
    </Text>
  );
}
