import {
  trackEvent,
  trackStructuredEvent,
} from 'js/helpers/google-tag-manager';
import { getItemByServiceId } from 'js/helpers/venue-menu';
import { MenuGroup, VenueOutput } from 'js/model/rainbow/venue/VenueOutput';
import { SalesPriceDiscountType } from 'js/model/rainbow/SalesPriceDiscountType';
import { PriceRangeOutput } from 'js/model/rainbow/PriceRangeOutput';
import { VenueMenuItemTypeOutput } from 'js/model/rainbow/venue/VenueMenuItemTypeOutput';
import { mParticleEventType, mParticleLogEvent } from '@treatwell/ui';

export enum Event {
  BookingFlowStart = 'bookingFlowStart',
  ServiceDetailsShow = 'serviceDetailsShow',
  MenuGroupSelected = 'menuGroupSelected',
  AddToBasket = 'addToBasket',
  RemoveFromBasket = 'removeFromBasket',
}

export enum Category {
  Venue = 'venue',
}

export enum Action {
  BookingFlowStart = 'booking_flow_start',
  ServiceDetailsShow = 'service_details_show',
  MenuGroupSelected = 'menu_group_selected',
  RemoveFromBasket = 'remove_from_basket',
  AddToBasket = 'add_to_basket',
  StartBookingFlow = 'start_booking_flow',
  HandoverModalShow = 'handover_modal_show',
}

interface Offer {
  id: number;
  type: string;
  price: string;
  maxPrice: string;
  categoryId?: number;
  treatmentGroupId?: number;
  name?: string;
  smartDiscount: boolean;
}

function trackBasketEvent(
  event: Event.AddToBasket | Event.RemoveFromBasket,
  action: Action.AddToBasket | Action.RemoveFromBasket,
  item: VenueMenuItemTypeOutput
): void {
  const data: { [key: string]: unknown } = {};

  data[event] = {
    data: {
      list: 'venue_page',
      quantity: 1,
    },
    action: {
      category: Category.Venue,
      name: action,
    },
    context: {
      offer: getTrackingOffer(item),
    },
  };

  return trackEvent(event, data);
}

export function getTrackingOffer(item: VenueMenuItemTypeOutput): Offer {
  const { data, type } = item;
  const id = parseInt(/[A-Z]+(\d+)/.exec(data.id)![1], 10);
  const discountTypes = data.priceRange.yieldDiscountTypes;
  const price = data.priceRange.minSalePriceAmount;
  const maxPrice = (data.priceRange as PriceRangeOutput & {
    maxFullPriceAmount: string;
  }).maxFullPriceAmount;

  return {
    id,
    type,
    price,
    maxPrice,
    categoryId: data.primaryTreatmentCategoryId,
    treatmentGroupId: item.data.primaryTreatmentCategoryGroupId,
    name: data.name,
    smartDiscount: [
      SalesPriceDiscountType.Jit,
      SalesPriceDiscountType.Offpeak,
    ].some(discountType => discountTypes.includes(discountType)),
  };
}

export function trackBookingFlowEvent(
  fulfillmentType: string,
  price: number | string,
  items: VenueMenuItemTypeOutput[]
): Promise<void> {
  const offers = items.map(getTrackingOffer);
  const offerIds = offers.map(offer => offer?.id);
  const quantity = offers.length;

  return trackEvent(Event.BookingFlowStart, {
    bookingFlowStart: {
      data: {
        fulfillmentType: fulfillmentType.toLowerCase(),
        offerType: offers[0]?.type,
      },
      action: {
        category: Category.Venue,
        name: Action.BookingFlowStart,
      },
      context: {
        basket: {
          offerIds,
          quantity,
          offers,
          total: price,
        },
      },
    },
  });
}

export function trackServiceDetailsEvent(item: VenueMenuItemTypeOutput): void {
  trackEvent(Event.ServiceDetailsShow, {
    serviceDetailsShow: {
      action: {
        name: Action.ServiceDetailsShow,
        category: Category.Venue,
        label: item?.data.id,
      },
      data: {},
      context: {
        offer: getTrackingOffer(item),
      },
    },
  });
}

export function trackMenuGroupSelectEvent(menuGroup: MenuGroup): void {
  trackEvent(Event.MenuGroupSelected, {
    menuGroupSelected: {
      action: {
        name: Action.MenuGroupSelected,
        category: Category.Venue,
      },
      data: {
        menuGroupId: menuGroup.id,
        menuGroupName: menuGroup.name,
      },
    },
  });
}

export function trackServiceSelect(
  servicesToAdd: number[],
  servicesToRemove: number[],
  menuGroups: MenuGroup[]
): void {
  if (servicesToRemove.length) {
    trackBasketEvent(
      Event.RemoveFromBasket,
      Action.RemoveFromBasket,
      getItemByServiceId(servicesToRemove[0].toString(), menuGroups).item!
    );
  }
  if (servicesToAdd.length) {
    trackBasketEvent(
      Event.AddToBasket,
      Action.AddToBasket,
      getItemByServiceId(servicesToAdd[0].toString(), menuGroups).item!
    );
  }
}

export function trackCorrectBasketEvent(
  action: Action.AddToBasket | Action.RemoveFromBasket,
  trackingGroupType: string,
  serviceId: number
): void {
  trackStructuredEvent({
    category: Category.Venue,
    action,
    label: trackingGroupType,
    value: serviceId,
  });
}

export function trackBookingFlowStart(numberOfServices: number): void {
  trackStructuredEvent({
    category: Category.Venue,
    action: Action.StartBookingFlow,
    value: numberOfServices,
  });
}

export function trackHandoverModalShow(venueId: number): void {
  trackStructuredEvent({
    category: Category.Venue,
    action: Action.HandoverModalShow,
    value: venueId,
  });
}

export const trackMParticleVenuePageEvent = (
  eventName: string,
  country: string,
  isWebMobile: boolean,
  venue: VenueOutput,
  triggerContext?: string
) => {
  mParticleLogEvent(eventName, mParticleEventType.Other, {
    country,
    platform: 'web',
    is_web_mobile: isWebMobile,
    venue_id: venue.id,
    venue_rating_displayed: venue.rating.displayAverage,
    venue_reviews_amount_displayed: venue.rating.count,
    venue_amenity_ids: venue.amenityGroups?.length
      ? venue.amenityGroups.map(group => group.id).join(' ,')
      : undefined,
    trigger_context: triggerContext || 'venue_menu',
  });
};
